import { gql } from '@sport-thieme/react-authenticator'

export const appListQuery = gql`
  query dashboardAppListQuery($email: String!) {
    favouriteApps: favouriteAppByUser(email: $email) {
      app {
        id
        name
        image
        slug
        description
      }
    }
  }
`
export type AppListQueryParameterType = {
  email: string
}
export type AppListQueryResultType = {
  favouriteApps: FavouriteApps
}

export type FavouriteApps = Array<{ app: AppType }>

export const currentUserQuery = gql`
  query dashboardAppCurrentUserQuery {
    currentUser {
      id
      email
      image
      firstname
      lastname
      apps {
        id
        name
        image
        slug
        description
      }
    }
  }
`

export type CurrentUserQueryResultType = {
  currentUser: CurrentUserType
}

export type CurrentUserType = {
  id: number
  firstname: string
  lastname: string
  email: string
  image: string
  apps: AppType[]
}

export type AppType = {
  id: number
  name: string
  slug: string
  image: string
  description: string
}
