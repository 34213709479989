import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import LogCollector from '@sport-thieme/react-log-collector'
import Authenticator, { GraphProvider, InMemoryCache } from '@sport-thieme/react-authenticator'
import { ThemeProvider } from '@sport-thieme/react-ui'
import AppContextProvider from './AppContext'
import * as serviceWorker from './serviceWorker'
import { ThemeOptions } from '@sport-thieme/react-ui/components/ThemeProvider/ThemeProvider'
import { AppType } from './graphql/queries'

const overrides = {
  MuiAppBar: {
    colorPrimary: {
      backgroundColor: 'transparent',
      boxShadow: 'none',
      color: '#444',
    },
  },
}

const theme: ThemeOptions = {
  palette: {
    primary: {
      main: '#0D4983',
    },
    secondary: {
      main: '#F2C94C',
    },
    background: {
      default: '#F1F4F9',
    },
  },
  overrides,
}

const darkTheme: ThemeOptions = {
  palette: {
    primary: {
      main: '#4b98e0',
    },
    secondary: {
      main: '#F2C94C',
    },
  },
  overrides,
}

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        favouriteAppByUser: {
          merge(_, incoming: Array<{ app: AppType }>) {
            return incoming
          },
        },
      },
    },
  },
})

ReactDOM.render(
  <React.StrictMode>
    <LogCollector>
      <ThemeProvider theme={theme} darkTheme={darkTheme}>
        <Authenticator>
          <GraphProvider cache={cache}>
            <AppContextProvider>
              <App />
            </AppContextProvider>
          </GraphProvider>
        </Authenticator>
      </ThemeProvider>
    </LogCollector>
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
