import React, { useState } from 'react'
import {
  makeStyles,
  Icon,
  IconButton,
  Grid,
  Paper,
  H5,
  Subtitle1,
  Tooltip,
  Avatar,
  Link,
} from '@sport-thieme/react-ui'
import { localize } from '@sport-thieme/util-localization'
import {
  appListQuery,
  AppListQueryParameterType,
  AppListQueryResultType,
  AppType,
  currentUserQuery,
  CurrentUserQueryResultType,
} from '../graphql/queries'
import { useMutation } from '@apollo/client'
import {
  createFavouriteAppMutation,
  CreateFavouriteAppReturnType,
  deleteFavouriteAppMutation,
  DeleteFavouriteAppReturnType,
  FavouriteMutationInputType,
} from '../graphql/mutations'
import { useQuery } from '@sport-thieme/react-authenticator'

export default function AppCard({ app }: AppCardProps): JSX.Element {
  const classes = useStyles()
  const [src, setSrc] = useState(app.image)
  const { slug, name, id, isFavourite, description } = app

  const { data: { currentUser } = {} } = useQuery<CurrentUserQueryResultType>(currentUserQuery)

  const variables = {
    appId: id,
    email: currentUser?.email || '',
  }

  const cacheVariables = {
    email: currentUser?.email || '',
  }

  const [addFavourite, { loading: loadingAddFavourite }] = useMutation<
    CreateFavouriteAppReturnType,
    FavouriteMutationInputType
  >(createFavouriteAppMutation, {
    variables,
    update: (proxy) => {
      const data = proxy.readQuery<AppListQueryResultType, AppListQueryParameterType>({
        query: appListQuery,
        variables: cacheVariables,
      })

      const newData = {
        apps: currentUser?.apps || [],
        favouriteApps: [
          ...(data?.favouriteApps ?? []),
          { app: { ...app, __typename: 'FavouriteApp' } },
        ],
      }

      proxy.writeQuery({
        query: appListQuery,
        variables: cacheVariables,
        data: newData,
      })
    },
  })

  const [deleteFavourite, { loading: loadingDeleteFavourite }] = useMutation<
    DeleteFavouriteAppReturnType,
    FavouriteMutationInputType
  >(deleteFavouriteAppMutation, {
    variables,
    update: (proxy) => {
      const data = proxy.readQuery<AppListQueryResultType, AppListQueryParameterType>({
        query: appListQuery,
        variables: cacheVariables,
      })

      const newData = {
        apps: currentUser?.apps || [],
        favouriteApps: [...(data?.favouriteApps ?? []).filter(({ app: { id } }) => app.id !== id)],
      }

      proxy.writeQuery({
        query: appListQuery,
        variables: cacheVariables,
        data: newData,
      })
    },
  })

  const handleFavouriteClick = async (e: { preventDefault: () => void }) => {
    e.preventDefault()
    try {
      if (isFavourite) {
        deleteFavourite()
      } else {
        addFavourite()
      }
    } catch {}
  }

  const loading = loadingAddFavourite || loadingDeleteFavourite

  return (
    <Grid item xs={12} sm={6} lg={4}>
      <Link
        href={`https://${slug}.sport-thieme.io`}
        target="_blank"
        rel="noreferrer"
        underline="none"
        color="inherit"
      >
        <Paper withPadding elevation={0} className={classes.height}>
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <Avatar
                src={`https://pimage.sport-thieme.de/icon512/${src}`}
                alt={name}
                onError={() => setSrc('service-app-icon')}
              />
            </Grid>
            <Grid item xs>
              <H5>{app.name || localize`unknown`}</H5>
              <Tooltip
                title={app.description || ''}
                enterDelay={1000}
                enterNextDelay={1500}
                placement="bottom-start"
              >
                <Subtitle1 className={classes.customBox}>{description}</Subtitle1>
              </Tooltip>
            </Grid>
            <Grid item>
              <IconButton loading={loading} onClick={handleFavouriteClick}>
                {isFavourite ? <Icon.Grade color="secondary" /> : <Icon.GradeOutlined />}
              </IconButton>
            </Grid>
          </Grid>
        </Paper>
      </Link>
    </Grid>
  )
}

const useStyles = makeStyles(() => ({
  customBox: {
    display: '-webkit-box', // what happens in none webkit browsers?
    boxOrient: 'vertical',
    lineClamp: 1,
    wordBreak: 'break-all',
    overflow: 'hidden',
  },
  height: {
    height: '100%',
  },
}))

export type AppCardProps = {
  app: AppCardType
}

export type AppCardType = AppType & {
  isFavourite: boolean
}
