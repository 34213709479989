import React from 'react'
import { Grid, makeStyles, H5 } from '@sport-thieme/react-ui'
import AppCard, { AppCardType } from './AppCard'

export default function AppList({ title, apps, isFavourite = false }: AppListProps): JSX.Element {
  const classes = useStyles()

  if (isFavourite && apps?.length === 0) {
    return <></>
  }

  return (
    <Grid container className={classes.padding} spacing={3}>
      {apps && apps?.length > 0 && (
        <Grid item xs={12}>
          <H5>{title}</H5>
        </Grid>
      )}
      {apps?.map((app) => (
        <AppCard key={app.name} app={app} />
      ))}
    </Grid>
  )
}

const useStyles = makeStyles(() => ({
  padding: {
    paddingTop: '2%',
  },
}))

type AppListProps = {
  title: string
  apps?: AppCardType[]
  isFavourite?: boolean
}
