import React from 'react'
import {
  AuthenticatorContext,
  useLazyQuery,
  useQuery,
  useToken,
} from '@sport-thieme/react-authenticator'
import {
  Alert,
  Box,
  Clipboard,
  Container,
  CssBaseline,
  Grid,
  Icon,
  IconButton,
  LocalhostCheck,
  P,
  PagePreloader,
  SimpleAppBar,
  SimpleDialog,
  TextField,
  makeStyles,
  useDarkMode,
} from '@sport-thieme/react-ui'
import { SearchFieldProps } from '@sport-thieme/react-ui/components/SearchField/SearchField'
import Localization, { localize } from '@sport-thieme/util-localization'
import { ReactElement, useContext, useEffect, useState } from 'react'
import { AppCardType } from './components/AppCard'
import AppList from './components/AppList'
import {
  AppListQueryParameterType,
  AppListQueryResultType,
  AppType,
  CurrentUserQueryResultType,
  appListQuery,
  currentUserQuery,
} from './graphql/queries'
import de from './resources/de.json'

Localization.addTranslations('de', de)

const App = (): ReactElement => {
  const { signOut } = useContext(AuthenticatorContext)
  const token = useToken()
  const [showToken, setShowToken] = useState(false)
  const [search, setSearch] = useState('')
  const [dark] = useDarkMode()
  const classes = useStyles()

  const {
    data: { currentUser } = {},
    loading: loadingUser,
    error: errorUser,
  } = useQuery<CurrentUserQueryResultType>(currentUserQuery)

  const [
    queryFavApps,
    { data: { favouriteApps } = { favouriteApps: [] }, loading: loadingFavApps },
  ] = useLazyQuery<AppListQueryResultType, AppListQueryParameterType>(appListQuery, {
    variables: { email: currentUser?.email ?? '' },
  })

  const normalizedFavouriteApps: AppCardType[] = favouriteApps.map(({ app }) => ({
    ...app,
    isFavourite: true,
  }))

  const normalizedApps: AppCardType[] | undefined = currentUser?.apps
    ?.filter(
      ({ id }) =>
        normalizedFavouriteApps.find(({ id: favouriteId }) => favouriteId === id) === undefined,
    )
    .map((app) => ({
      ...app,
      isFavourite: false,
    }))

  useEffect(() => {
    if (currentUser !== undefined) {
      queryFavApps()
    }
  }, [currentUser, queryFavApps])

  if (loadingUser || loadingFavApps) return <PagePreloader />

  const Logo = () => {
    return (
      <Box textAlign="center">
        <img
          width="350"
          className={classes.logo}
          height="82"
          src={`https://pimage.sport-thieme.de/logo-st/siteLogo${dark ? '-white' : ''}-noSubline`}
          alt="Sport-Thieme"
        />
      </Box>
    )
  }

  const searchFieldProps: SearchFieldProps | undefined =
    currentUser && currentUser.apps?.length > 10
      ? {
          onChange: (e) => {
            setSearch(e.target.value)
          },
          value: search,
          autoFocus: true,
          clearFilter: () => setSearch(''),
        }
      : undefined

  const searchFilter = ({ name }: AppType) => search === '' || new RegExp(search, 'i').test(name)

  return (
    <>
      <Container>
        <CssBaseline />
        <LocalhostCheck />
        <SimpleAppBar
          signOut={signOut}
          username={currentUser?.firstname}
          avatarImage={currentUser?.image}
          showMenuIcon={false}
          search={searchFieldProps}
          menuItems={[
            {
              label: localize`Show token`,
              onClick: setShowToken.bind(null, true),
            },
          ]}
        />
        <Logo />
        {errorUser ? (
          <Alert severity="error">{errorUser.message}</Alert>
        ) : (
          <Box paddingTop={2}>
            {currentUser?.apps && currentUser?.apps?.length > 0 ? (
              <>
                <AppList
                  title={localize`Favourite Apps`}
                  apps={normalizedFavouriteApps?.filter(searchFilter)}
                  isFavourite
                />
                <AppList title={localize`Apps`} apps={normalizedApps?.filter(searchFilter)} />
              </>
            ) : (
              <P>{localize`Sorry, there is nothing to show here. Try to log in or ask for permissions to see the apps list.`}</P>
            )}{' '}
          </Box>
        )}
      </Container>
      <SimpleDialog
        title={localize`Your token`}
        open={showToken}
        onClose={setShowToken.bind(null, false)}
        disablePortal
        disableEnforceFocus
        maxWidth="lg"
        fullWidth
      >
        <Grid container>
          <Grid item grow>
            <TextField multiline fullWidth value={token} />
          </Grid>
          <Grid item>
            <Clipboard text={token}>
              <IconButton>
                <Icon.FileCopy />
              </IconButton>
            </Clipboard>
          </Grid>
        </Grid>
        <br />
        <P>
          {localize`Please do not share this token with anyone unless you are asked to do so by IT. Anyone can use this token to impersonate you and access your data. You can invalidate the token by logging out.`}
        </P>
      </SimpleDialog>
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  logo: {
    maxWidth: '100%',
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
    },
  },
}))

export default App
