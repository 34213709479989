import { gql } from '@sport-thieme/react-authenticator'

export const createFavouriteAppMutation = gql`
  mutation dashboardAppCreateFavouriteApp($appId: Int!, $email: String!) {
    createFavouriteApp(data: { appId: $appId, email: $email }) {
      id
      appId
    }
  }
`

export type CreateFavouriteAppReturnType = {
  createFavouriteApp: {
    id: number
    appId: number
  }
}

export type FavouriteMutationInputType = {
  appId: number
  email: string
}

export const deleteFavouriteAppMutation = gql`
  mutation dashboardAppDeleteFavouriteApp($email: String!, $appId: Int!) {
    deleteFavouriteApp(where: { email: $email, appId: $appId })
  }
`

export type DeleteFavouriteAppReturnType = {
  deleteFavouriteApp: boolean
}
