import React, { ReactNode, createContext, useState } from 'react'

type ProviderProps = {
  children: ReactNode
}

type AppContextType = {
  title: string
  setTitle: React.Dispatch<React.SetStateAction<string>>
}

export const AppContext = createContext<AppContextType>({
  title: '',
  setTitle: () => undefined,
})

export default function AppContextProvider({ children }: ProviderProps): JSX.Element {
  const [title, setTitle] = useState('')

  return (
    <AppContext.Provider
      value={{
        title,
        setTitle,
      }}
    >
      {children}
    </AppContext.Provider>
  )
}
